import React from "react"
import SEO from '../components/seo'
import Header from "../components/Header"
import Footer from "../components/Footer"

const ToolboxPage = () => {
  
  return (
    <div>
      <SEO
         title="Best Product Management Resources"
      />
       <Header />
       <div className="section-container content">
       Toolkit

       https://spotify.design/article/figma-template-for-your-next-remote-design-sprint
       https://www.figma.com/community/file/961940615273396965

       Interview Questions


       </div>
       <Footer />
    </div>
  )
}

export default ToolboxPage